import './bots-table-row.scoped.css';
import React from 'react';
import moment from 'moment';
import { ReactComponent as BotNameIcon } from '@/assets/bot-name-icon.svg';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import { ReactComponent as BotLinkIcon } from '@/assets/link-attach-btn.svg';
import Tooltip from '@/components/Tooltip/Tooltip';

const tdStyle = {
  textAlign: 'center',
};

const SingleBotItem = ({
  markedBot,
  onClick,
  botDetails: { bot_name, owner, lastUpdate, createdAt, is_active, status, usage, botId, botUrl },
}) => {
  return (
    <tr
      style={{
        backgroundColor: markedBot ? 'var(--main-undertone-clr)' : 'var(--white-clr)',
        border: '10px solid var(--chat-background-clr)',
      }}
      className="single-bot-item"
    >
      <td>
        <input type="checkbox" id={botId} className="checkbox" />
        <label onClick={() => onClick(botId)} htmlFor={botId}>
          {markedBot ? <CheckActiveIcon /> : <CheckIcon />}
        </label>
      </td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginLeft: '30%' }}>
          <BotNameIcon style={{ cursor: 'auto' }} />
          <Tooltip value={bot_name}>
            <div className="icon-name-container">
              <p>{bot_name ? bot_name : botId}</p>
            </div>
          </Tooltip>
        </div>
      </td>
      <td style={tdStyle}>{owner}</td>
      <td style={tdStyle} dir="ltr">
        {moment(lastUpdate)
          .locale('en')
          .format('D MMM, YYYY')}
      </td>
      <td style={tdStyle} dir="ltr">
        {moment(createdAt)
          .locale('en')
          .format('D MMM, YYYY')}
      </td>

      <td style={tdStyle}>{is_active} </td>
      <td style={tdStyle}>{status}</td>
      <td style={tdStyle}>{usage || '--'}</td>
      <td style={{ textAlign: 'right', width: '50px', height: '50px' }}>
        <a href={`${botUrl}/${botId}`} target="_blank">
          <BotLinkIcon />
        </a>
      </td>
    </tr>
  );
};

export default SingleBotItem;
