import 'moment/locale/he';
import { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { ReactComponent as HeartIcon } from '@/assets/blue-heart.svg';
import { ReactComponent as ActiveHeartIcon } from '@/assets/active-heart-icon.svg';
import { ReactComponent as DeleteHistory } from '@/assets/blue-trash.svg';
import { ReactComponent as CloseIcon } from '@/assets/modal-close-icon-lm.svg'
import { useAtom } from 'jotai';
import { userAtom, submitAtom } from '@/atoms/general';
import { isPreviewAtom } from '@/atoms/preview';
import { inputsAtoms } from '@/atoms/inputs';
import { chosenDocumentsAtom, documentsOpenPopUpAtom, markedDocumentsAtom } from '@/atoms/documents';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { activeLogAtom } from '@/atoms/logs';
import { prevLogIdAtom } from '@/atoms/login';
import Lottie from 'react-lottie';
import animationData from '@/assets/three-dot-animation.json';
import moment from 'moment';
import { ReactComponent as HistoryIcon } from '@/assets/history-docs-icon.svg';
import WordImg from '@/assets/word-img.png';
import PdfImg from '@/assets/pdf-img.png';
import { v4 as uuidv4 } from 'uuid';
import './documents-history.scoped.css';
import { htmlDecode } from '@/utils/html';
import axios from '@/configs/axios.js';

export const DocumentsHistory = ({ isPageEnglish, setIsCloseToolKit, onClose }) => {
  /// atoms
  const [inputs, setInputs] = useAtom(inputsAtoms);
  const [user, setUser] = useAtom(userAtom);
  const [isPreview, setIsPreview] = useAtom(isPreviewAtom);
  const [logSignal, setSignalLog] = useAtom(submitAtom);
  const [currentPreview, setCurrentPreview] = useState(false);
  const [prevLodId, setPrevLogId] = useAtom(prevLogIdAtom);
  const [markedDocuments, setMarkedDocuments] = useAtom(markedDocumentsAtom);
  const [chosenDocuments, setChosenDocuments] = useAtom(chosenDocumentsAtom);
  const [toolkitSettings, setToolKitSettings] = useAtom(toolKitSettingsAtom);
  const [activeLog, setActiveLog] = useAtom(activeLogAtom);
  const [, setDocumentsOpenPopUp] = useAtom(documentsOpenPopUpAtom);
  ///atoms end
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [historyToShow, setHistoryToShow] = useState([]);
  const [filterInput, setFilterInput] = useState('');
  const [currPage, setCurrPage] = useState(1);
  const [pagesAmount, setPagesAmount] = useState();
  const isSearching = useRef();
  const [searchInput, setSearchInput] = useState('');
  let historyFiltered = [...historyToShow];
  if (searchInput.trim()) {
    historyFiltered = historyToShow.map((group) => {
      return {
        ...group,
        days: group.days.filter((day) => {
          return (
            day.question[1].value.includes(searchInput.trim()) || day.answer.includes(searchInput.trim())
          );
        }),
      };
    });
  }
  console.log()
  useEffect(() => {
    const start = async () => {
      if (!user) {
        const loggedUser = sessionStorage.getItem('user');
        if (loggedUser) setUser(JSON.parse(loggedUser));
      }
      await handleFilterChange();
    };
    start();
  }, [user, filterInput, logSignal, currPage]);

  const handleFilterChange = async () => {
    clearTimeout(isSearching.current);
    if (filterInput) {
      isSearching.current = setTimeout(async () => {
        await getData();
      }, 100);
    } else {
      await getData();
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const getData = async () => {
    let historyDb = [];
    if (!user) return;

    const history = await axios.put('/api/logs/get-logs-history-documents', {
      userId: user.id,
      docId: markedDocuments[0].docId,
    });
    setIsInitialLoad(false);
    history.data.rows.forEach((day, index) => {
      let newDays = [];
      day.rows.forEach((doc) => {
        newDays.push({
          question: JSON.parse(doc.question),
          // questionInEnglish: JSON.parse(doc.question_in_english),
          answer: htmlDecode(doc.answer),
          toolkitSettings: JSON.parse(doc.toolkit_settings),
          answerInEnglish: htmlDecode(doc.answer_in_english),
          isShown: false,
          documentSelected:
            doc.documents_selected && doc.documents_selected.length
              ? JSON.parse(doc.documents_selected)
              : [],
          category: doc.category,
          whenAsked: doc.created_at,
          sentiment: doc.sentiment,
          time: calcDateEnglish(doc.created_at).time,
          tokenAmount: doc.token_amount,
          wordsAmount: doc.words_amount,
          isLike: doc.is_like ? doc.is_like.trim() === 'true' : false,
          id: doc.id,
          apiModel: doc.api_model,
        });
      });
      historyDb[index] = {
        dayFormat: calcDate(day.day),
        dayInEnglishFormat: calcDateEnglish(day.day),
        days: newDays,
      };
    });
    // history.data.rows.forEach((doc, index) => {
    //   historyDb[index] = {
    //     question: JSON.parse(doc.question),
    //     questionInEnglish: JSON.parse(doc.question_in_english),
    //     answer: htmlDecode(doc.answer),
    //     answerInEnglish: htmlDecode(doc.answer_in_english),
    //     isShown: false,
    //     category: doc.category,
    //     whenAsked: doc.created_at,
    //     whenAskedFormat: calcDate(doc.created_at),
    //     whenAskedFormatInEnglish: calcDateEnglish(doc.created_at),
    //     sentiment: doc.sentiment,
    //     tokenAmount: doc.token_amount,
    //     wordsAmount: doc.words_amount,
    //     isLike: doc.is_like ? doc.is_like.trim() === 'true' : false,
    //     id: doc.id,
    //     apiModel: doc.api_model,
    //   }
    // })

    setPagesAmount(history.data.pagesNumber);
    setHistoryToShow([...historyDb]);
  };

  const fetchLog = async (id, date, time) => {
    sessionStorage.setItem('chat', JSON.stringify(inputs));
    sessionStorage.setItem('documents', JSON.stringify(chosenDocuments));
    sessionStorage.setItem('toolkit', JSON.stringify(toolkitSettings));
    sessionStorage.setItem('log-id', JSON.stringify(prevLodId));
    setPrevLogId(id);
    setActiveLog({
      id: id,
      day: date.dayInEnglishFormat.day,
      dayNum: date.dayInEnglishFormat.dayNum,
      month: date.dayInEnglishFormat.month,
      year: date.dayInEnglishFormat.year,
      time,
    });

    try {
      const { data } = await axios.get(`/api/logs/${id}`);
      setInputs(JSON.parse(data.question));
      setToolKitSettings(JSON.parse(data.toolkit_settings));
      setChosenDocuments(
        isValidJson(data.documents_selected) ? JSON.parse(data.documents_selected) : []
      );
      setIsPreview(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const calcDate = (timestamp) => {
    moment.locale('he');

    const year = moment(timestamp).format('YYYY');
    const month = moment(timestamp).format('בMMMM');
    const day = moment(timestamp).format('D');
    const time = moment(timestamp).format('HH:mm');
    const date = { year: year, month: month, day: day, time: time };
    return date;
  };
  const calcDateEnglish = (timestamp) => {
    moment.locale('en');
    const year = moment(timestamp).format('YYYY');
    const month = moment(timestamp).format('MMMM');
    const dayNum = moment(timestamp).format('D');
    const day = moment(timestamp).format('dddd');
    const time = moment(timestamp).format('HH:mm');
    const date = { year: year, month: month, day: day, dayNum: dayNum, time: time };
    return date;
  };

  const handleCloseBtn = () => {
    // setActiveLog('');
    setIsPreview(false);
    onClose();
  }

  const deleteLog = async (e, logId) => {
    e.stopPropagation();
    let isWontToDelete;
    if (isPageEnglish) isWontToDelete = window.confirm('Are you sure it will be permanently deleted?');
    else isWontToDelete = window.confirm('האם ברצונך למחוק למציתות?');
    if (isWontToDelete) {
      await axios.post('/api/logs/delete', {
        logId: logId,
        userId: user.id,
      });
      setSignalLog((prev) => !prev);
    }
  };
  const isValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const restoreOldChat = async () => {
    sessionStorage.setItem('chat', inputs);
    sessionStorage.setItem('documents', JSON.stringify(chosenDocuments));
    sessionStorage.setItem('toolkit', JSON.stringify(toolkitSettings));

    setActiveLog('');
    setIsPreview(false);
    setIsCloseToolKit(true);
  };
  const backCurrentSession = async () => {
    if (isPreview) {
      const chat = JSON.parse(sessionStorage.getItem('chat'));
      const logId = JSON.parse(sessionStorage.getItem('log-id'));
      const documents = JSON.parse(sessionStorage.getItem('documents'));
      const toolkit = JSON.parse(sessionStorage.getItem('toolkit'));

      setPrevLogId(logId);
      setActiveLog('');
      // setIsCloseToolKit(true)
      setInputs(chat);
      setChosenDocuments(documents);
      setToolKitSettings(toolkit);
      sessionStorage.removeItem('chat');
      sessionStorage.removeItem('documents');
      sessionStorage.removeItem('toolkit');

      setDocumentsOpenPopUp('');
      setIsPreview(false);
    }
  };

  if (!user || !markedDocuments[0]) return;

  return (
    <div className="tool-kit">
      <div className="flex-upper-titles">
        <div className='first-row'>
          <span className="main-headline">
            {' '}
            <HistoryIcon /> Documents History
          </span>
          <button onClick={handleCloseBtn} className="close-btn">
            <CloseIcon style={{height:'22px'}} />
          </button>
        </div>
        <span className="second-headline"> {markedDocuments[0].docTitle}</span>
      </div>
      <input
        className="search-input"
        placeholder="Search"
        type="text"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <h1 className="history-subheadline">30-day history</h1>

      {isInitialLoad && !activeLog ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {' '}
          <div style={{ width: '100px', height: '25px', overflow: 'auto' }}>
            {/* loading history */}
            <Lottie options={defaultOptions} />
          </div>
        </div>
      ) : (
        <div className="ques-examples-page">
          {/* <input
          onClick={() => handleScreenInteraction('searchInHistory')}
          type="text"
          placeholder={!isPageEnglish ? 'חיפוש שאלות...' : 'Search question...'}
          className={isPageEnglish ? 'search-examples-input ltr' : 'search-examples-input'}
          value={filterInput}
          onChange={({ target }) => setFilterInput(target.value)}
        /> */}
          {historyFiltered &&
            historyFiltered.map((example, index) =>
              example.days.length === 0 ? (
                <></>
              ) : (
                <div key={uuidv4()} className="flex-days">
                  <p className="exact-date">
                    {example.dayInEnglishFormat.day} {example.dayInEnglishFormat.dayNum}{' '}
                    {example.dayInEnglishFormat.month}
                    {', '} {example.dayInEnglishFormat.year}
                  </p>
                  {example.days.map((day, innerIndex) => (
                    <div key={innerIndex}>
                      {innerIndex === 0 && index === 0 ? (
                        <>
                          <button
                            onClick={backCurrentSession}
                            className={`single-ques-ans ${!activeLog ? 'active-log' : ''}`}
                          >
                            <p className="now ltr">Now</p>
                            <div className="img-psuedo-container">
                              {day.question[1].files.length > 0 && (
                                <>
                                  {day.question[1].files.length > 1 && (
                                    <div className="files-counter">
                                      {' '}
                                      +{day.question[1].files.length - 1}
                                    </div>
                                  )}

                                  <img
                                    className="file-type-img"
                                    src={
                                      day.question[1].files[0].name.trim().includes('pdf')
                                        ? PdfImg
                                        : WordImg
                                    }
                                    alt="word image"
                                  />
                                </>
                              )}
                            </div>
                            <div className="answer-container">
                              <p className="current-editor ltr">Current version</p>
                            </div>
                          </button>
                          <button
                            key={day.id}
                            onClick={() => fetchLog(day.id, example, day.time)}
                            className={`single-ques-ans ${activeLog.id === day.id ? 'active-log' : ''}`}
                          >
                            <div />
                            <p className="history-ques-date ltr">{day.time}</p>
                            <div className="img-psuedo-container">
                              {day.question[1].files.length > 0 && (
                                <>
                                  {day.question[1].files.length > 1 && (
                                    <div className="files-counter">
                                      {' '}
                                      +{day.question[1].files.length - 1}
                                    </div>
                                  )}

                                  <img
                                    className="file-type-img"
                                    src={
                                      day.question[1].files[0].name.trim().includes('pdf')
                                        ? PdfImg
                                        : WordImg
                                    }
                                    alt="word image"
                                  />
                                </>
                              )}
                            </div>

                            <div className="answer-container">
                              <p className="answer ltr">{htmlDecode(day.answerInEnglish)}</p>
                            </div>
                          </button>
                        </>
                      ) : (
                        <button
                          key={day.id}
                          onClick={() => fetchLog(day.id, example, day.time)}
                          className={`single-ques-ans ${activeLog.id === day.id ? 'active-log' : ''}`}
                        >
                          <p className="history-ques-date ltr">{day.time}</p>
                          <div className="img-psuedo-container">
                            {day.question[1].files.length > 0 && (
                              <>
                                {day.question[1].files.length > 1 && (
                                  <div className="files-counter">
                                    {' '}
                                    +{day.question[1].files.length - 1}
                                  </div>
                                )}

                                <img
                                  className="file-type-img"
                                  src={
                                    day.question[1].files[0].name.trim().includes('pdf')
                                      ? PdfImg
                                      : WordImg
                                  }
                                  alt="word image"
                                />
                              </>
                            )}
                          </div>

                          <div className="answer-container">
                            <p className="answer ltr">{htmlDecode(day.answerInEnglish)}</p>
                          </div>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )
            )}
        </div>
      )}
    </div>
  );
};
