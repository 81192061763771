import React, { forwardRef } from 'react';
import { ReactComponent as DeleteImgIcon } from '@/assets/delete-img-icon.svg';
import './imageUpload.scoped.scss';

const ImageUploadButton = forwardRef(({ selectedImages, removeImg }, ref) => {
  return (
    <div>
      {selectedImages && selectedImages.length > 0 && (
        <div className="images-container">
          {selectedImages.map((image, index) => (
            <div className="image-container" key={index}>
              <DeleteImgIcon className="delete-btn" onClick={() => removeImg(image)} />
              <img key={index} src={image.imageUrl} alt={`Selected ${index}`} width="200" />
            </div>
          ))}{' '}
        </div>
      )}
    </div>
  );
});

export default ImageUploadButton;
