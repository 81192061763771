import { getFileUploadToken } from '@/api/jwt'
import { startDocumentProcessing, uploadFileToBlob } from '@/api/upload'

let serviceInstance = null
export const UPLOAD_PROGRESS_EVENT = 'upload-progress-event'
export const PROCESSING_FILE_STATUS = {
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAIL: 'fail',
}

export const PROCESSING_EVENT_TYPE = {
    START: 'start',
    DOWNLOAD: 'download',
    UPLOAD: 'upload',
    PREPARE: 'prepare',
    Q_AND_A: 'q&a',
    SAVE_DB: 'save-db',
    SAVE_VECTORS: 'save-vectors',
    COMPLETED: 'completed',
}
class FileQueueService {
    #queue
    #currentIndex
    #isProcessing
    constructor() {
        this.#queue = []
        this.#currentIndex = 0
        this.#isProcessing = false
    }

    getQueue() {
        return this.#queue
    }

    getStatusUpdate(event, previousStatus) {
        if (event.status === previousStatus) return previousStatus
        const validStatus = Object.values(PROCESSING_FILE_STATUS)
        if(!validStatus.includes(event.status)) {
            console.error(`Invalid status:`, event.status)
            return previousStatus
        }
        if(event.status === PROCESSING_FILE_STATUS.SUCCESS && PROCESSING_EVENT_TYPE.COMPLETED === event.type) {
            return PROCESSING_FILE_STATUS.SUCCESS
        }
        if(event.status === PROCESSING_FILE_STATUS.FAIL) {
            return PROCESSING_FILE_STATUS.FAIL
        }
        return PROCESSING_FILE_STATUS.LOADING
    }
    addCachedFiles(files) {
        // console.log('Adding cached files to queue:', files.length)
        this.#queue.push(...files)
    }
    addFiles(files, data, uploadConfig) {
        console.log('Adding files to queue:', files.length)
        const mapped = files.map((file) => {
            return {
                file,
                data,
                mediaKey: null,
                name: file.name,
                size: file.size,
                status: PROCESSING_FILE_STATUS.PENDING,
                finished: false,
                config: uploadConfig,
                folderTreeDisplayData: null,
            }
        })
        this.#queue.push(...mapped)
    }

    setFileStatus(event) {
        const index = this.#queue.findIndex((item) => item.mediaKey === event.resourceId)
        if (index === -1) {
            console.error(`setFileStatus, File not found in queue:`, event.resourceId)
            return
        }
        const file = this.#queue[index]
        const previousStatus = file.status


        this.#queue[index].status = this.getStatusUpdate(event, previousStatus)
        return this.#queue[index].status
    }

    getFileStatus(mediaKey) {
        const file = this.#queue.find((item) => item.mediaKey === mediaKey)
        if (!file) {
            console.error(`getFileStatus, File not found in queue:`, mediaKey)
            return
        }
        console.log(`${file.name} status:`, file.status)
        return file.status
    }

    setIsProcessing(value) {
        if (typeof value !== 'boolean') {
            throw new Error('isProcessing must be boolean')
        }
        this.#isProcessing = value
    }
    getIsProcessing() {
        return this.#isProcessing
    }
    getCurrentItem() {
        return this.#queue[this.#currentIndex] || null
    }
    getNextQueueItem() {
        const currentItem = this.getCurrentItem()

        if (currentItem && currentItem.finished !== true) {
            return currentItem
        }

        const nextIndex = this.#queue.findIndex((item) => item.finished !== true)
        if (nextIndex !== -1) {
            this.#currentIndex = nextIndex
            return this.#queue[nextIndex]
        }

        return null
    }
    setFinishedCurrentFile() {
        this.#queue[this.#currentIndex].finished = true
        this.setIsProcessing(false)
    }
    removeFileById(id) {
        const index = this.#queue.findIndex((item) => item.id === id)
        if (index === -1) {
            console.error(`removeFileById, File not found in queue:`, id)
            return false
        }
        this.#queue.splice(index, 1)
        return true
    }
    removeFileByIndex(index) {
        const removedElement = this.#queue.splice(index, 1)
        if(!removedElement){
            console.error(`removeFileByIndex, File not found in queue:`, index)
            return false
        }
        console.log(`Removed file from queue:`, removedElement)
        return true
    }
    setFailedCurrentFile() {
        this.#queue[this.#currentIndex].status = PROCESSING_FILE_STATUS.FAIL
        this.#queue[this.#currentIndex].finished = true
        this.setIsProcessing(false)
    }
    progressCb = ({progress, resourceId, status}) => {
        const mediaKey = resourceId || this.#queue[this.#currentIndex].mediaKey
        if(!mediaKey) {
            console.error(`progressCb, mediaKey is not defined`)
            return
        }
        dispatchEvent(new CustomEvent(UPLOAD_PROGRESS_EVENT, { detail: { resourceId: mediaKey, status, progress } }))
    }
    async documentProcessingPipeline({ user, blobUrl, path }) {
        try {

            const item = this.getNextQueueItem()
            if (!item || item.finished) {
                console.log(`No items in queue or current item is completed`)
                return null
            }

            this.#queue[this.#currentIndex].status = PROCESSING_FILE_STATUS.LOADING


            this.setIsProcessing(true)
            const { file, data, config } = item
            const name = item.name || file.name
            console.log(`Started processing file:`, name)


            const { uploadData, vectorMethods, isQuestionGenerator } = data

            // Get token
            const tokenRes = await getFileUploadToken(name)
            if (!tokenRes) {
                console.error(`Failed to get token for file ${file.name}`)
                return null
            }

            // Upload file to blob
            const { fileToken, mediaKey } = tokenRes
            const { folderId = null, folderPath } = config;
            file.mediaKey = mediaKey
            this.#queue[this.#currentIndex].mediaKey = mediaKey
            this.progressCb({ progress: 25, resourceId: mediaKey, status: PROCESSING_FILE_STATUS.LOADING })
            const pathRefactored = {
                ids: folderPath.map((item) => item.id),
                names: folderPath.map((item) => item.name),
            };

            const processingData = {
                metadata: { originalname: file.name, filename: file.name },
                userId: user.id,
                docDescription: uploadData.docDescription || '',
                docTitle: file.name,
                chunkSizeFromUser: uploadData.chunkSize,
                overlapSizeFromUser: uploadData.chunkOverLap,
                vectorMethods,
                vectorLanguage: uploadData.vectorLanguage,
                folderId,
                pathToDocument: pathRefactored,
                isQuestionGenerator: isQuestionGenerator,
            }

            const uploadRes = await uploadFileToBlob(
                {
                    content: file.content,
                    fileToken,
                    mediaKey,
                    file,
                    blobPath: blobUrl,
                    metadata: processingData,
                },
            )

            if (!uploadRes) {
                console.error(`Failed to upload file ${file.name}`)
                this.setFailedCurrentFile()
                this.progressCb({ status: PROCESSING_FILE_STATUS.FAIL, resourceId: mediaKey, progress: 100 })
                return null
            }

            const startProcessingRes = await startDocumentProcessing({ mediaKey, ...processingData })
            if (!startProcessingRes) {
                console.error(`Failed to start processing file ${file.name}`)
                this.setFailedCurrentFile()
                this.progressCb({ status: PROCESSING_FILE_STATUS.FAIL, resourceId: mediaKey, progress: 100 })
                return null
            }
            const { docId } = startProcessingRes

            const fileData = {
                created_at: new Date().toISOString(),
                doc_description: '',
                doc_id: docId,
                doc_name_originL: file.name,
                doc_size: file.size,
                doc_summery: '',
                doc_summery_modified_at: '',
                doc_summery_modified_by: '',
                doc_title: file.name,
                doc_type: file.type,
                folder_id: folderId,
                owner_id: user.id,
                tags: [],
                vector_methods: JSON.stringify(vectorMethods),
                mediaKey,
            }
            this.#queue[this.#currentIndex].folderTreeDisplayData = fileData
            return fileData
        } catch (error) {
            console.error('Error processing file:', error)
            this.setIsProcessing(false)
            return null
        }
    }
    getFileFolderTreeDisplayData(mediaKey) {
        const file = this.#queue.find((item) => item.mediaKey === mediaKey)
        if (!file) {
            console.error(`getFileFolderTreeDisplayData, File not found in queue:`, mediaKey)
            return null
        }
        return file.folderTreeDisplayData
    }
}

export default function createFileQueueService() {
    if (!serviceInstance) {
        console.log(`Creating new FileQueueService instance`)
        serviceInstance = new FileQueueService()
    }
    return serviceInstance
}
