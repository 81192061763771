import './tooltip.scoped.css';
import React, { useState } from 'react';

const Tooltip = ({ value, children, style, isMultiplier }) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const multiplierX = 0.4;
    const multiplierY = 0.8;
    setTooltipPosition({
      top: isMultiplier ? (multiplierY * clientY) -30  : clientY + 10,
      left: isMultiplier ? multiplierX * clientX + 100 : clientX + 10,
    });
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const handleClick = () => {
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <div
      className="tooltip-container"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{ cursor: isCopied ? 'default' : 'pointer' }}
    >
      {children}
      <div
        className="tooltip"
        style={{
          ...style,
          top: tooltipPosition.top,
          left: tooltipPosition.left,
          position: 'fixed',
          visibility: isTooltipVisible ? 'visible' : 'hidden',
          opacity: isTooltipVisible ? 0.9 : 0,
        }}
      >
        {isCopied ? 'Copied!' : value}
      </div>
    </div>
  );
};

export default Tooltip;
