import './inputForm.scoped.scss'
import { ReactComponent as LeftIcon } from '@/assets/left-icon-lm.svg'

import { ReactComponent as RightIcon } from '@/assets/right-icon-lm.svg'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useState, useRef, useEffect } from 'react'

export const InputForm = ({
  generateAnswer,
  question,
  role,
  handleToggleRole,
  handleChange,
  placeholder,
  // handleScreenInteraction,
  isPageEnglish,
  selectedImage,
  setSelectedImage,
}) => {
  const textareaRef = useRef()

  const [alignment, setAlignment] = useState('left')

  function adjustTextareaHeight() {
    const textarea = textareaRef.current
    if (!textarea) return
    // Reset height to 'auto' to get the correct scrollHeight
    textarea.style.height = 'auto'
    // Set height to scrollHeight to remove scrollbar
    textarea.style.height = textarea.scrollHeight + 'px'
  }
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', adjustTextareaHeight)
    return () => {
      window.removeEventListener('resize', adjustTextareaHeight)
    }
  })

  function pasteIntoInput(el, text) {
    if (el){
      el.focus()
      if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
        var val = el.value
        var selStart = el.selectionStart
        el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
        el.selectionEnd = el.selectionStart = selStart + text.length
      } else if (typeof document.selection != 'undefined') {
        var textRange = document.selection.createRange()
        textRange.text = text
        textRange.collapse(false)
        textRange.select()
      }
    }
  }

  return (
    <div className="comment-container">
      <div className="upper-strip">
        <button className="input-role" onClick={() => handleToggleRole(role)}>
          Text
        </button>

        <div className="directions-btns-flex">
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton
              value={alignment === 'right' ? 'left' : 'right'}
              aria-label="left aligned"
              sx={{
                border: 'none',
                '&:hover': { backgroundColor: 'transparent' },
                '&.Mui-selected, &.Mui-selected:hover': {
                  backgroundColor: 'transparent',
                  // Add any other styles you want for the active state here
                },
              }}
            >
              {alignment === 'left' ? (
                <RightIcon className={`${alignment === 'right' ? 'alignment-icon-active' : ''}`} />
              ) : (
                <LeftIcon className={`${alignment === 'left' ? 'alignment-icon-active' : ''}`} />
              )}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <form className="question-input-form" onSubmit={(ev) => generateAnswer(ev, undefined)}>
        <div style={{ width: '100%' }} className="input-container">
          <textarea
            ref={textareaRef}
            style={{ textAlign: alignment, direction: alignment === 'right' ? 'rtl' : 'ltr' }}
            // onClick={() => handleScreenInteraction('EnterQuestionInputFromDesktop')}
            onKeyDown={(ev) => {
              if (ev.keyCode === 13 && ev.shiftKey) {
                pasteIntoInput(textareaRef.current, '\n');
                ev.preventDefault();
                return;
              }
              if (ev.keyCode === 13) {
                ev.preventDefault();
                generateAnswer(ev, undefined);
              }
            }}
            alt="type your text here"
            className={`${isPageEnglish ? 'question-input ' : 'question-input'}`}
            type="text"
            placeholder={placeholder}
            value={question}
            onChange={handleChange}
          />
        </div>
      </form>
    </div>
  )
}
