import axios from '@/configs/axios.js';
import { BlockBlobClient } from '@azure/storage-blob';
import { UPLOAD_PROGRESS_EVENT } from '../services/fileQueueService';

export const getOngoingDocumentProcessing = async () => {
    try {
        const res = await axios.post(`/api/v1/doc/processing-tasks`);
        if (res.status !== 200) {
            throw new Error(`Error getting ongoing document processing, got: ${res.status}`);
        }
        return res.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export const startDocumentProcessing = async ({
    mediaKey,
    metadata,
    content,
    userId,
    docDescription,
    docTitle,
    chunkSizeFromUser,
    overlapSizeFromUser,
    vectorMethods,
    vectorLanguage,
    folderId,
    pathToDocument,
    isQuestionGenerator,
    sync,
}) => {
    try {
        const res = await axios.post('/api/v1/doc/start-processing', {
            mediaKey: mediaKey,
            metadata: metadata,
            userId: userId,
            docDescription: docDescription,
            docTitle: docTitle,
            chunkSizeFromUser: chunkSizeFromUser,
            overlapSizeFromUser: overlapSizeFromUser,
            vectorMethods: vectorMethods,
            vectorLanguage: vectorLanguage,
            folderId: folderId,
            pathToDocument: pathToDocument,
            isQuestionGenerator: isQuestionGenerator
        });
        if (res.status !== 200) {
            throw new Error(`Error sending file processing, got: ${res.status}`);
        }
        return res.data;
    } catch (err) {
        console.error('Error sending file processing:', err);
        return null
    }
};
export const SUPPORTED_FILE_EXTENSIONS = {
    DOC: 'doc',
    DOCX: 'docx',
    PDF: 'pdf',
    DEFAULT: 'file',
}
export function getFileTypeByExtension(extension) {
    switch (extension) {
        case SUPPORTED_FILE_EXTENSIONS.DOC:
            return 'application/msword'
        case SUPPORTED_FILE_EXTENSIONS.DOCX:
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case SUPPORTED_FILE_EXTENSIONS.PDF:
            return 'application/pdf';
        default:
            return 'application/octet-stream';
    }
}
export const uploadFileToBlob = async ({ content, file, fileToken, mediaKey, blobPath, metadata }) => {
    try {
        const blobUrl = `${blobPath}/${mediaKey}?${fileToken}`;

        const blockBlobClient = new BlockBlobClient(blobUrl);

        const fileExtension = file.name.split('.').pop().toLowerCase();

        const blobContentType = getFileTypeByExtension(fileExtension);
        const parseMetadataForAzure = (obj, prefix = '') => {
            const delimeter = "_"
            return Object.entries(obj).reduce((acc, [key, value]) => {
            const newKey = prefix ? `${prefix}${delimeter}${key}` : key;
            if (typeof value === 'object' && value !== null) {
                Object.assign(acc, parseMetadataForAzure(value, newKey));
            } else {
                if (/[\u0080-\uFFFF]/.test(value)) {
                    console.warn(`Non-ASCII character found in metadata value: ${value}`);
                    value = encodeURIComponent(value);
                }
                acc[newKey] = String(value);
            }
            return acc;
            }, {});
        };

        const blobMetadata = parseMetadataForAzure(metadata);
        console.log({ blobMetadata });
        const uploadOptions = {
            blobHTTPHeaders: { blobContentType: blobContentType },
            blockSize: 1 * 1024 * 1024, // 4MB block size
            onProgress: (progress) => {
                console.log({ progress });
                console.log(`File size ${file.size}, uploaded ${progress.loadedBytes}`);
                const percentCompleted = Math.round((progress.loadedBytes / file.size) * 100);
                console.log({ percentCompleted });
                dispatchEvent(new CustomEvent(UPLOAD_PROGRESS_EVENT, { detail: { resourceId: mediaKey, progress: percentCompleted } }))
            },
            metadata: blobMetadata,
        };

        const response = await blockBlobClient.uploadData(content, uploadOptions);
        return response;
    } catch (err) {
        console.error('Error uploading file to blob:', err);
        return null
    }
};
