import './voice-table.scoped.css';
import React, { useState } from 'react';
// import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as AudioFileIcon } from '@/assets/audio-file-icon.svg';
import { DocumentInfoModal } from '@/components/InfoModal/DocumentInfoModal';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import moment from 'moment';
import { useAtom } from 'jotai';
import { isShowStateAtom } from '@/atoms/upload';
import UploadStatus from '@/components/UploadStatus/UploadStatus';
import TableHeaderSort from '@/components/TableHeaderSort/TableHeaderSort';
import Tooltip from '@/components/Tooltip/Tooltip';

function VoiceTable({ callsToShowUser, settings }) {
  const [isShowStatus, setIsShowStatus] = useAtom(isShowStateAtom);
  const [callsToShowUserByPhoneNum] = useState([]);
  const [callsToShowUserByFilter] = useState([]);
  const [chosenFile, setChosenFile] = useState('');
  const [sortOrder, setSortOrder] = useState(1);

  const handleButtonClick = (id) => {
    setChosenFile(id);
  };

  const mainFiles =
    callsToShowUserByPhoneNum.length > 0
      ? callsToShowUserByPhoneNum
      : callsToShowUser
      ? callsToShowUser
      : callsToShowUserByFilter
      ? callsToShowUserByFilter
      : [];

  const chosenDocument = mainFiles.filter((document) => document.id === chosenFile);

  function handleSort(arr, key) {
    arr.sort((a, b) => {
      if (key === 'last_updated') {
        const dateA = moment(a[key], 'DD MMM, YYYY').valueOf();
        const dateB = moment(b[key], 'DD MMM, YYYY').valueOf();
        return (dateA - dateB) * sortOrder;
      } else {
        const valA = a[key].toLowerCase();
        const valB = b[key].toLowerCase();
        return (valA > valB ? 1 : valA < valB ? -1 : 0) * sortOrder;
      }
    });
    setSortOrder(-sortOrder);
  }

  return (
    <>
      <div className="table-container">
        <table>
          <thead
            style={{
              backgroundColor: 'var(--chat-background-clr)',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <tr
              style={{
                top: 0,
                zIndex: 1,
              }}
            >
              {settings[0].options.map((header, index) => {
                return (
                  <TableHeaderSort
                    key={index}
                    type={header.type}
                    value={header.type === 'header' ? header.value : ''}
                    style={header.style}
                    handler={
                      header.type === 'header'
                        ? handleSort
                        : {
                            value: false,
                            // func: handleMarkAllChange,
                          }
                    }
                    arr={callsToShowUser}
                    isSelect={header.isSelect}
                    table={settings[1].type}
                    isSort={header.isSort}
                  />
                );
              })}
            </tr>
          </thead>

          <tbody>
            {callsToShowUser.length > 0 &&
              callsToShowUser.map((file, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <input type="checkbox" id={file.id} className="checkbox" />
                      <label htmlFor={file.id}>{<CheckIcon />}</label>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="icon-name-container">
                          <AudioFileIcon style={{ cursor: 'auto',flexShrink: 0 }} />
                          <Tooltip value={file.file_name} style={{ alignItems: 'left' }}>
                            <span>{file.file_name}</span>
                          </Tooltip>
                        </div>
                      </div>
                    </td>
                    <td style={{ textAlign: 'center', direction: 'ltr' }}>
                      {moment(file.created_at)
                        .locale('en')
                        .format('D MMM, YYYY')}
                    </td>
                    <td style={{ textAlign: 'center' }}>{file.duration}</td>
                    <td style={{ textAlign: 'center' }}>{file.error}</td>
                    <td style={{ textAlign: 'center' }}>{file.country}</td>
                    <td style={{ textAlign: 'center' }}>
                      <InfoIcon
                        style={{ fontSize: 'xx-large', color: 'var(--main-clr)' }}
                        onClick={() => handleButtonClick(file.id)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {isShowStatus && <UploadStatus onClose={() => setIsShowStatus(!isShowStatus)} />}
      {chosenFile && (
        <DocumentInfoModal
          isOpen={true}
          header={`הקלטה #`}
          onClose={() => {
            setChosenFile('');
          }}
          document={chosenDocument[0]}
          duration={document.duration}
        />
      )}
    </>
  );
}

export default VoiceTable;
